.mainSideMenuButton{
    width:100%;
    max-width: 25vw;
    float: right;
    padding-bottom: 4px;
    margin: 5px;
}

.sideMenuButton{
    width: 100%;
    height: 100%;
    color: lightgrey;
    transition: background-color .4s ease;
    background-color:rgba(255, 255, 255, 0.21);
    border-radius: .25rem;
    border: 2px solid #007bff;
    font-size: 1rem;
    white-space: nowrap;
    pointer-events: auto;
}

.sideMenuButton:hover{
    background-color: rgb(0, 255, 21, 0.4);
}

.sideMenuButton:active{
    background-color: rgba(0,0,255,.25);
}
