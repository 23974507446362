.solverInfo{
    position: fixed;
    color: lightgray;
    top: 85px;
    right: 10px;
    font-size: 1rem;
    width: 50%;
    max-width: 200px;
}

.setLengthWrapper{
    width: 100%;
    text-align: left;
    display: inline-block;
}

.setLength{
    border: none;
    background-color: #007bff;
    height: 25px;
    width: 80px;
    border-radius: .25rem;
    margin-bottom: 5px;
    text-align: center;
    float: right;
}