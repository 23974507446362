.menuWrapper{
    position: fixed;
    bottom: 5px;
    width: 100%;
    height:30%;
    max-height:30%;
    min-height: 160px;
    background: rgba(red, green, blue, 0);
    border-radius: .25rem;
    overflow: hidden;
    padding: 5px;
    padding-top: 0px;
    /* border: 1px solid #007bff; */
}

.menuWrapperOptions{
    position: fixed;
    bottom: 0px;
    width: 100%;
    height:30%;
    min-height: 160px;
    overflow: hidden;
}

.menuToggle{
    position: fixed;
    margin-left: 50%;
    bottom: 5px;
    width: 40px!important;
    height: 20px!important;
    z-index: 100000000000;
    left: -20px;
    font-size: 2rem!important;
    text-align: center;
    vertical-align: middle;
    line-height: 10px;
    padding-top: 8px;
}
