#controlsDiv{
    width:100%;
    height:100%;
    background-color: transparent;
    white-space: nowrap;
  }

  .centerMoves{
    display: inline-block;
    height:100%;
    overflow: hidden;
  }

  .singleLayerMoves{
    display: inline-block;
    height:100%;
    overflow: hidden;
  }
  .centerLayerColumns,.singleLayerColumns,.multiLayerColumns{
    display: inline-block;
    width:min-content;
    height:100%;
    white-space: pre-wrap;
  }
  .multiLayerMoves{
    display: inline-block;
    height:100%;
    overflow: hidden;
    /* float: left; */
  }
  .moveBtn{
    border-radius: .25rem;
    font-size: .75rem;
    height: 14%;
    margin-bottom:4px;
    margin-right: 4px;
    pointer-events: auto;
  }

  .moveBtn:hover{
      background-color: lightblue!important;
      color: black!important;
  }