.bottomMenuWrapper{
    position:absolute;
    bottom:0;
    left: 0;
    width: 100%;
    height: 25vh;
}

.bottomExitDiv{
    visibility: visible;
    position:absolute;
    padding: 4px;
    bottom: 4px;
    right: 0px;
    height: 50%;
    width: 25vw;
    max-width: 400px;
    max-height: 150px;
    font-size: 2rem;
}

.buttonWrapper{
    height:25vh;
    max-height: 300px;
    width: 100%;
}

.controllerBox{
    visibility: visible;
    margin:auto;
    height:100%;
    max-width: 400px;
}

#controlsPopup{
    max-height: 300px;
    margin:auto;
    bottom: 4px;
}

.sideMenuWrapper{
    min-width: 30vw;
    max-width: 30vw;
    min-height: 100%;
    position: absolute;
    bottom:0;
    padding-top: 72px;
    right:0;
}

.sideMenu{
  width: 100%;
  height:100%;
  position: relative;
  bottom:0px;
}

.sideMenuBox{
    padding: 4px;
    height:30vh;
    width:100%;
    border: 2px solid rgb(52, 58, 64);
    position: relative;
    bottom: 30vh;
  }

  .sideMenuBox0{
    padding: 4px;
    height:60vh;
    width:100%;
    position: relative;
    bottom: -72px;
    /* left: -1250px; */
}

.sideMenuBox1{
    height:49%;
    width:100%;
    border-radius: .25rem;
    position: relative;
}

.sideMenuBox2{
    padding-top: 4px;
    height:49%;
    width:100%;
    border-radius: .25rem;
    bottom: 0;
}

.sideMovesBox{

    height: 100%;
    width:100%;
    overflow-y: scroll;
    transition: border .4s ease;
    border: 2px solid rgb(52, 58, 64);
    border-radius: .25rem;
    pointer-events: auto;
    bottom:0;
    left: 0;
    color: #007bff;
}

.sideLimit{
    max-width:400px;
    right:0px;
}
