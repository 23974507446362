.warningPopup{
    display:none;
    position: absolute;
    background-color: rgba(25,25,25,.7);
    border: 2px solid rgb(52, 58, 64);
    border-radius: .25rem;
    z-index: 100;
    color: lightgrey;
    height: 100%;
    max-height: 300px;
    width:30vw;
    margin-bottom: 100%;
}

.cpMessage{
    margin:auto;
    padding: 10px;
}

.colorButtonContainer{
  visibility: visible;
}

.cpLeaveStay{
    color: lightgrey;
    transition: background-color .4s ease;
    background-color: rgba(0,0,255,.10);
    border-radius: .25rem;
    border: 2px solid #007bff;
    font-size: 1rem;
    height: 22.5%;
    width: 40%;
    padding-left: 6px;
    padding-right: 6px;
    white-space: nowrap;
    margin: 5px;
    pointer-events: auto;
  }

  .cpLeaveStay:hover{
    background-color: rgba(0,0,255,.5)!important;
  }

  .cpLeaveStay:active{
    background-color: rgba(0,0,255,.25)!important;
  }

.solveCpDiv,.checkCpDiv{
  margin-top: 20%;
}

.cpErrorMessage{
  color:red;
  font-size:.8rem;
  text-align:left;
  list-style:none;
  margin:2px;
  line-height: 15px;
}

.solveCp,.checkCp{
  width:50%;
  min-width: 100px;
  min-height: 50px;
  border-radius: .25rem;
  border: 2px solid lightgrey!important;
  transition: background-color .4s ease;
  background-color: rgba(211,211,211,.1);
  pointer-events: auto;
}

.solveCp:hover{
  background-color: rgba(0,100,0,.5)!important
}

.checkCp:hover{
  background-color: rgba(0,0,100,.5)!important
}

  .triggerBtn{
    width:100%;
    height:20%;
    font-size: 1rem;
    background-color: #343a40;
    color: lightgray;
    border-radius: .25rem;
    border: 1px solid #007bff;
  }

.cpInfo{
  position: absolute;
  bottom: 0;
  left: 0;
  width:98%;
  height:100%;
  max-width: 400px;
  border: 2px solid rgb(52, 58, 64);
  border-radius: .25rem;
}

  .colorButtonContainer{
    max-width: 400px;
    height: 100%;
    max-height: 300px;
    float: right;
  }

  .colorButtonDiv{
    width:50%;
    height:25%;
    display: inline-block;
    padding-bottom: 4px;
  }

  .bottomColorButtonDiv{
    width:50%;
    height:33%;
    display: inline-block;
    padding-bottom: 4px;
  }

  .colorPicker{
    width:100%;
    height:100%;
    display: inline-block;
    border: 2px solid #007bff!important;
    border-radius: .25rem;
    transition: background-color .4s ease;
    pointer-events: auto;
  }

  .colorPickerExit{
    color: lightgrey;
    
    background-color: rgba(211,211,211,.1);
    border-radius: .25rem;
    border: 2px solid lightgrey!important;
    margin-bottom: 0px;
  }

  .colorPickerExit:hover{
    background-color: rgba(0,0,255,.5)!important
  }

  .white{
    margin-top: 0px;
    color: lightgray;
    border-color: lightgray;
    margin-right: 2%;
  }
  .white:hover{
    background-color: rgba(255,255,255,.3)!important;
  }

  .blue{
    margin-top: 0px;
    color: blue;
    border-color: blue;
  }
  .blue:hover{
    background-color: rgba(0,0,255,.3)!important;
  }

  .red{
    color: red;
    border-color: red;
    margin-right: 2%;
  }
  .red:hover{
    background-color: rgba(255,0,0,.3)!important;
  }

  .yellow{
    color: yellow;
    border-color: yellow;
  }
  .yellow:hover{
    background-color: rgba(255,255,0,.3)!important;
  }

  .orange{
    color: orange;
    border-color: orange;
    margin-right: 2%;
  }
  .orange:hover{
    background-color: rgba(255,165,0,.3)!important;
  }

  .green{
    color: green;
    border-color: green;
  }
  .green:hover{
    background-color: rgba(0,255,0,.3)!important;
  }

  .rightCp{
    padding-left: 1%;
  }

  .leftCp{
    padding-right: 1%;
  }