.mobileButton{
    border: 2px solid #007bff;
    color: lightgrey;
    transition: background-color .4s ease;
    background-color:rgba(0,0,255,.10);
    border-radius: .25rem;
    display: block;
    width: 100%;
    height:30%;
    margin-bottom: 4px;
}

.mobileButton:hover{
    background-color:rgba(0,0,255,.10);
}

.mobileButton:active{
    background-color: rgba(0,0,255,.5);
}

.blankButton{
    opacity: 0;
    border-radius: .25rem;
    display: block;
    width:100%;
    height:30%;
    margin-bottom: 4px;
}

.solverMovesSolver{
    height:200px!important;
}